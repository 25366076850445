const html = document.querySelector('html');
const body = document.querySelector('body');
const header = document.querySelector('.l-header');
const menuButton = document.querySelector('.l-header__sp-menu-button');
const menuButtonText = document.querySelector('.js-change-text');
const spNav = document.querySelector('.l-header__nav-list');
const spCTA = document.querySelector('.l-header__cta-list');
const spButton = document.querySelector('.l-header__button');
const spOverlay = document.querySelector('.l-header__overlay');
const mvHeightTarget = document.querySelector('.js-mv-height-target');
const mvHeight = mvHeightTarget.clientHeight;
const windowHeight = document.documentElement.clientHeight;
const mediaQueryList = window.matchMedia('(min-width: 768px)');

const listener = (event) => {
  if (event.matches) {
    return;
  } else {
    headerScroll();
    menuButton.addEventListener('click', spMenuToggle);
    spNav.addEventListener('click', spMenuToggle);
    spCTA.addEventListener('click', spMenuToggle);
    spButton.addEventListener('click', spMenuToggle);
    spOverlay.addEventListener('click', spMenuToggle);
  }
};
mediaQueryList.addEventListener('change', listener);
listener(mediaQueryList);

function spMenuToggle() {
  if(header.classList.contains('is-open')) {
    header.classList.remove('is-open');
    html.style.overflow = 'initial';
    body.style.overflow = 'initial';
    menuButtonText.textContent = 'MENU';
  } else {
    header.classList.add('is-open');
    html.style.overflow = 'hidden';
    body.style.overflow = 'hidden';
    menuButtonText.textContent = 'CLOSE';
  }
}

function headerScroll() {
  window.addEventListener('scroll', function(){
    if (windowHeight < scrollY) {
      header.classList.add('is-fixed');
    } else {
      header.classList.remove('is-fixed');
    }
  }, false)

}