import Swiper, { Autoplay, Pagination, EffectFade } from 'swiper';

Swiper.use([Autoplay, Pagination, EffectFade]);

new Swiper('.p-top-mv__slide-wrap', {
  slidesPerView: 1,
  loop: true,
  speed: 1500,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  allowTouchMove: false,
  pagination: {
    el: '.p-top-mv__swiper-pagination',
    clickable: true,
    type: 'bullets',
    bulletElement: 'svg',
    renderBullet: function(index, className) {
      return `
      <button type="button" class="${className}">
        <span class="center-circle"></span>
        <span class="outer-circle">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g fill="none" stroke="#d2d4d6" stroke-width="0.5">
            <circle cx="10" cy="10" r="10" stroke="none"/>
            <circle cx="10" cy="10" r="9.65" fill="none"/>
          </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" class="animation-circle">
            <circle cx="12" cy="12" r="10" />
          </svg>
        </span>
      </button>`
    }
  },
  breakpoints: {
    768: {
      loop: false,
      autoplay: false,
      touchRatio: 0,
      SimulateTouch: false,
    }
  }
});

setTimeout(() => {
  const firstMvSlideArray = [...document.querySelectorAll('[data-swiper-autoplay]')];
  firstMvSlideArray.forEach((firstMvSlide) => {
    firstMvSlide.setAttribute('data-swiper-autoplay', 3000);
  });
} ,4600);
