const header = document.querySelector('.l-header');
const headerHeight = header.clientHeight;
const body = document.querySelector('body');
const mediaQueryList = window.matchMedia('(min-width: 768px)');

const listener = (event) => {
  if (event.matches) {
    body.style.paddingTop = `${headerHeight}px`;
  } else {
    body.style.paddingTop = `0px`;
  }
};
mediaQueryList.addEventListener('change', listener);
listener(mediaQueryList);
