const loader = document.querySelector('.js-loading');

window.addEventListener('load', stopload);

setTimeout(() => {
  let loaded = document.querySelector('.js-loading');
  if(loaded.className !== 'p-loading js-loading is-loaded'){
    stopload();
  }
},7000);

function stopload() {
  setTimeout(() => {
    loader.classList.add('is-loaded');
  },1000)
}