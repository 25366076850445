const mailTarget = [...document.querySelectorAll('.js-mail-target')];

function converter(M) {
  var str="", str_as="";
  for(var i = 0; i < M.length; i++) {
    str_as = M.charCodeAt(i);
    str += String.fromCharCode(str_as + 1);
  }
  return str;
}

const address = converter(String.fromCharCode(114,96,109,106,100,109,63,114,96,109)+String.fromCharCode(106,100,109,44,118,110,110,99,45,98,110,108));
const mailElement = `<a target="_blank" href="mailto:${address}">${address}</a>
                    <noscript>
                      <img src="/assets/images/top/mail_noscript_img.png" alt="" loading="lazy" width="275" height="39">
                    </noscript>`;

mailTarget.forEach((mail) => {
  mail.innerHTML = mailElement;
})